/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from "react";
import {
  useGLTF,
  CurveModifier,
  Text3D,
  CurveModifierRef,
} from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import { fontFilePaths } from "../fonts/fontFilePaths";

function CircularText() {
  const textRef = useRef<THREE.Mesh>(null);
  const curvedPoints = [];
  const curvedPoints2 = [];
  const curvedPoints3 = [];
  const radius = 16;
  const segments = 50; // Increase this for smoother curves
  const curveRef = useRef<CurveModifierRef>(null);
  const curveRef2 = useRef<CurveModifierRef>(null);
  const curveRef3 = useRef<CurveModifierRef>(null);
  const [fontNameIdx, setFontNameIdx] = React.useState(0);
  const fontName = "/fonts/Faustina Light_Regular.json";

  // every frame, move the sun around the cloud
  useFrame((state, delta) => {
    // every 10 frames change the font name
    // if (state.clock.getElapsedTime() % 10 < delta) {
    //   setFontNameIdx((prev) =>
    //     fontNameIdx < fontFilePaths.length - 1 ? prev + 1 : 0,
    //   );
    // }

    if (curveRef.current) {
      curveRef.current.moveAlongCurve(0.001);
    }
    if (curveRef2.current) {
      curveRef2.current.moveAlongCurve(0.001);
    }
    if (curveRef3.current) {
      curveRef3.current.moveAlongCurve(0.001);
    }
  });
  for (let i = 0; i < segments; i++) {
    const angle = (i / segments) * 2 * Math.PI;
    curvedPoints.push(
      new THREE.Vector3(Math.sin(angle) * radius, 0, -Math.cos(angle) * radius),
    );
    curvedPoints2.push(
      new THREE.Vector3(
        Math.sin(angle + (2 * Math.PI) / 3) * radius,
        0,
        -Math.cos(angle + (2 * Math.PI) / 3) * radius,
      ),
    );
    curvedPoints3.push(
      new THREE.Vector3(
        Math.sin(angle + (4 * Math.PI) / 3) * radius,
        0,
        -Math.cos(angle + (4 * Math.PI) / 3) * radius,
      ),
    );
  }
  const curve = new THREE.CatmullRomCurve3(curvedPoints, true);
  const curve2 = new THREE.CatmullRomCurve3(curvedPoints2, true);
  const curve3 = new THREE.CatmullRomCurve3(curvedPoints3, true);

  return (
    <group>
      <CurveModifier curve={curve} ref={curveRef}>
        <Text3D
          ref={textRef}
          font={fontName} // Make sure to use a valid font path
          size={-2.5}
          bevelEnabled
          bevelThickness={0.05}
          bevelSize={0.01}
          bevelOffset={0}
          bevelSegments={64}
          rotation={[0, 0, Math.PI]} // Rotate the text to correct orientation
        >
          Matty Sun
          <meshPhysicalMaterial color="white" reflectivity={10} />
        </Text3D>
      </CurveModifier>
      <CurveModifier curve={curve2} ref={curveRef2}>
        <Text3D
          ref={textRef}
          font={fontName} // Make sure to use a valid font path
          size={-2.5}
          bevelEnabled
          bevelThickness={0.05}
          bevelSize={0.01}
          bevelOffset={0}
          bevelSegments={64}
          rotation={[0, 0, Math.PI]} // Rotate the text to correct orientation
        >
          Matty Sun
          <meshPhysicalMaterial color="white" />
        </Text3D>
      </CurveModifier>
      <CurveModifier curve={curve3} ref={curveRef3}>
        <Text3D
          ref={textRef}
          font={fontName} // Make sure to use a valid font path
          size={-2.5}
          bevelEnabled
          bevelThickness={0.05}
          bevelSize={0.01}
          bevelOffset={0}
          bevelSegments={64}
          rotation={[0, 0, Math.PI]} // Rotate the text to correct orientation
        >
          Matty Sun
          <meshPhysicalMaterial color="white" />
        </Text3D>
      </CurveModifier>
    </group>
  );
}

export const MattyText = () => {
  return (
    <group>
      <directionalLight color="white" position={[0, 0, 15]} layers={1} />
      <CircularText />
    </group>
  );
};

useGLTF.preload("/models/scene-v1.glb");
