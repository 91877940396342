/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from "react";
import { CameraControls, useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { Mesh } from "three";

const deg2Rad = (deg: number) => {
  return deg * (Math.PI / 180);
};

interface ISunCloudsProps {
  width: number;
}

export const Model = ({ width }: ISunCloudsProps) => {
  const cameraFinalPos = width < 800 ? 50 : 40;
  const cameraStartPos = width < 800 ? 70 : 60;
  const { camera } = useThree();

  camera.position.set(0, 0, cameraStartPos); // Set position like this
  camera.rotateX(deg2Rad(180)); // Rotate like this
  camera.lookAt(new THREE.Vector3(0, 0, 0)); // Set look at coordinate like this
  camera.layers.enable(1);

  const { nodes } = useGLTF("/models/scene-v1.glb");
  const cloudMaterial = new THREE.MeshStandardMaterial({
    color: "white",
  });
  const cloud1 = useRef<THREE.Group<THREE.Object3DEventMap>>(null!);
  const cloud2 = useRef<THREE.Group<THREE.Object3DEventMap>>(null!);
  const cameraRef = useRef(cameraStartPos);

  const moveSpeedRef = useRef(10);
  const cameraMoveSpeedRef = useRef(13);
  const sunMaterial = new THREE.MeshStandardMaterial({ color: "#FDD022" });

  useEffect(() => {
    camera.layers.enable(0);
    if (cloud1.current && cloud2.current) {
      cloud1.current.position.set(4, 0.5, 20);
      cloud2.current.position.set(-4, 0.5, 19);
    }
  }, []);

  // every frame, move the sun around the cloud
  useFrame((state, delta) => {
    const dist = delta * 4.2 * moveSpeedRef.current;
    moveSpeedRef.current = Math.max(moveSpeedRef.current * 0.99, 3);

    const cameraDist = delta * 1.8 * cameraMoveSpeedRef.current;
    cameraMoveSpeedRef.current = Math.max(cameraMoveSpeedRef.current * 0.97);
    if (cameraRef.current > cameraFinalPos) {
      cameraRef.current = cameraRef.current - cameraDist;
      state.camera.position.lerp(
        new THREE.Vector3(0, 0, cameraRef.current),
        0.1,
      );
    }

    if (!cloud1.current || !cloud2.current) return;
    const cloud1Pos = cloud1.current.position;
    cloud1Pos.x = cloud1Pos.x + dist;
    // if (cloud1Pos.x > 60) cloud1Pos.x = 60;

    const cloud2Pos = cloud2.current.position;
    cloud2Pos.x = cloud2Pos.x - dist;
    // if (cloud2Pos.x < -60) cloud2Pos.x = -60;
  });

  return (
    <group dispose={null}>
      {/* <CameraControls minPolarAngle={0} maxPolarAngle={Math.PI / 1.6} /> */}
      <directionalLight position={[0, 0, 30]} intensity={0.5} />
      <group ref={cloud1} rotation={[0, deg2Rad(-20), deg2Rad(10)]}>
        <mesh
          geometry={(nodes.Cloud__0 as THREE.Mesh).geometry}
          material={cloudMaterial}
          scale={3}
          rotation={[deg2Rad(-86), deg2Rad(10), deg2Rad(-10)]}
          layers={0}
        />
      </group>
      <group ref={cloud2} rotation={[0, deg2Rad(-20), deg2Rad(10)]}>
        <mesh
          geometry={(nodes.Cloud__0 as THREE.Mesh).geometry}
          material={cloudMaterial}
          scale={3}
          rotation={[deg2Rad(-86), deg2Rad(10), deg2Rad(-10)]}
          layers={0}
        />
      </group>
      <group position={[-25, 0, -20]} rotation={[0, deg2Rad(-20), deg2Rad(10)]}>
        <mesh
          geometry={(nodes.Cloud__0 as THREE.Mesh).geometry}
          material={cloudMaterial}
          scale={2}
          rotation={[deg2Rad(-86), deg2Rad(10), deg2Rad(-10)]}
          layers={0}
        />
      </group>
      <group position={[25, 0, -20]} rotation={[0, deg2Rad(-20), deg2Rad(10)]}>
        <mesh
          geometry={(nodes.Cloud__0 as THREE.Mesh).geometry}
          material={cloudMaterial}
          scale={2}
          rotation={[deg2Rad(-86), deg2Rad(10), deg2Rad(-10)]}
          layers={0}
        />
      </group>
      <group position={[0, 0, -30]} rotation={[0, deg2Rad(-20), deg2Rad(10)]}>
        <mesh
          geometry={(nodes.Cloud__0 as THREE.Mesh).geometry}
          material={cloudMaterial}
          scale={3}
          rotation={[deg2Rad(-86), deg2Rad(10), deg2Rad(-10)]}
          layers={0}
        />
      </group>

      <mesh
        position={[0, 0, 0]}
        scale={15}
        geometry={(nodes.Sphere_sun_0 as THREE.Mesh).geometry}
        material={sunMaterial}
        layers={0}
      />
    </group>
  );
};

useGLTF.preload("/models/scene-v1.glb");
